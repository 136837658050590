import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

interface LoaderProps {
  loading: boolean;
  size?: number;
  color?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  loading,
  size = 15,
  color = '#9333EA',
}) => {
  return (
    <div className="loader">
      <SyncLoader loading={loading} size={size} color={color} />
    </div>
  );
};

export default Loader;
