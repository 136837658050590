import React, { useState, useEffect } from 'react';
import { api } from '../../api';
import { Modal } from '../../components/Modal';
import { Toast } from '../../components/Toast';
import ModernButton from '../../components/Button';

interface SaleDeclaration {
  id: number;
  user_id: string;
  declared_at: string;
  detail: string;
  campaign_id: string | null;
  closure_id: number | null;
  n: number;
}

export const Declaration: React.FC = () => {
  // Estado para manejar las declaraciones
  const [declarations, setDeclarations] = useState<SaleDeclaration[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);

  // Estado para la nueva declaración
  const [newSaleDeclaration, setNewSaleDeclaration] = useState({
    n: 1,
  });

  const token = localStorage.getItem('token');

  // Función para obtener las declaraciones
  const fetchDeclarations = async () => {
    try {
      const response = await fetch(api.declarationProcess, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const reverse = data.reverse();
        setDeclarations(reverse);
      } else {
        console.error('Error al cargar las declaraciones');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchDeclarations();
  }, []);

  // Función para agregar la nueva declaración
  const handleAddDeclaration = async () => {
    setIsLoading(true);
    setShowToast(true);
    setToastMessage('Procesando nueva declaración...');

    try {
      const response = await fetch(api.declarationProcess, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newSaleDeclaration),
      });

      if (response.ok) {
        const result = await response.json();
        setToastMessage('¡Nueva declaración añadida con éxito!');
        setDeclarations((prev) => [result, ...prev]); // Actualizar la lista de declaraciones
        setNewSaleDeclaration({
          n: 1,
        }); // Limpiar los campos
      } else {
        setToastMessage('Error al añadir la declaración');
      }
    } catch (error) {
      console.error('Error:', error);
      setToastMessage('Error al añadir la declaración');
    } finally {
      setIsLoading(false);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  return (
    <div className="overflow-x-auto max-w-4xl mx-auto mt-10">
      <h5 className="mb-5 text-6xl font-bold text-center text-blue-600">
        Declaraciónes de Venta
      </h5>

      {/* Tabla de declaraciones */}
      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <tr>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              ID
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Cantidad
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Fecha
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Fila para agregar una nueva declaración */}
          <tr className="bg-[#483D8B]">
            <td className="px-6 py-4 text-center">
              {/* Espacio vacío en la columna ID */}
            </td>
            <td className="px-6 py-4 text-center">
              {/* Campo para cantidad */}
              <input
                type="number"
                value={newSaleDeclaration.n}
                onChange={(e) =>
                  setNewSaleDeclaration((prev) => ({
                    ...prev,
                    n: Number(e.target.value),
                  }))
                }
                className="w-32 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
              />
            </td>
            <td className="px-6 py-4 text-center"></td>
            <td className="px-6 py-4 text-center">
              <ModernButton
                text="Agregar"
                onClick={handleAddDeclaration}
                className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              />
            </td>
          </tr>

          {/* Filas de declaraciones existentes */}
          {declarations.map((declaration) => (
            <tr
              key={declaration.id}
              className="hover:bg-blue-50 transition-colors"
            >
              <td className="px-6 py-4 text-center">{declaration.id}</td>
              <td className="px-6 py-4 text-center">{declaration.n}</td>
              <td className="px-6 py-4 text-center">
                {new Date(declaration.declared_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Botón de "Declarar"
      <ModernButton
        text="Declarar"
        className="flex justify-self-center mt-6"
        onClick={() => setIsModalOpen(true)}
        disabled={disabledDeclaration}
      /> */}

      {/* Modal de confirmación */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleAddDeclaration}
        title="Confirmar Declaración"
      >
        <p>¿Estás seguro de que deseas declarar las órdenes seleccionadas?</p>
      </Modal>

      {/* Toast */}
      <Toast
        isVisible={showToast}
        message={toastMessage ?? ''}
        isLoading={isLoading}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};
