import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  // Obtener el nombre del usuario y su rol desde localStorage
  const userName = localStorage.getItem('userName') || 'No Name';
  const userRole = localStorage.getItem('userRole') || 'No Role';

  console.log(userName, 'ussername');
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName'); // Asegurarte de remover también los datos del usuario
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  return (
    <div className="bg-blue-500 text-white w-full h-12 flex items-center justify-end px-4">
      {/* Detalles del usuario con círculo icono */}
      <div className="flex items-center">
        <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>{' '}
        {/* Círculo como icono de usuario */}
        <span className="mr-4">
          {userName} ({userRole})
        </span>
        {/* Botón de Cerrar sesión */}
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  );
};

export default Navbar;
