export const fetchData = async (url: string, options = {}) => {
  const token = localStorage.getItem('token');
  console.log('Token:', token); // Depuración del token

  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '', // Asegúrate de que el token esté configurado correctamente
      },
      ...options, // Permite sobrescribir opciones como método, body, etc.
    });

    console.log('Response status:', response.status); // Depuración del estado HTTP

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Fetched data:', data); // Depuración de los datos recibidos
    return data;
  } catch (error) {
    console.error('Error in fetchData:', error); // Muestra detalles del error
    throw error; // Lanza el error para que pueda ser capturado en el componente
  }
};
