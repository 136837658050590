import React, { useEffect, useState } from 'react';
import { api } from '../../../../api';
import { fetchData } from '../../utils';
import ImageModal from '../imageModal';

interface Order {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  detail_id: string;
}

export const Inventory: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const fetchOrders = async () => {
    const data = await fetchData(api.orderDetail);
    if (data) {
      setOrders(data);
    } else {
      console.error('Error al obtener las órdenes');
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Calcula los índices de inicio y fin para los elementos visibles
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleOrders = orders.slice(startIndex, endIndex);

  const totalPages = Math.ceil(orders.length / itemsPerPage);

  // Función para generar la URL de la imagen usando `detail_id`
  const getImageUrl = (detailId: string) => {
    const [code, number] = detailId.split('-');
    return `https://stickerseller-images-2.s3.amazonaws.com/${code}/${number}.JPG`;
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="overflow-x-auto max-w-4xl mx-auto mt-10">
      <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
        Inventario de Productos
      </h5>

      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <tr>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              ID
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Estado
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Creado En
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Actualizado En
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Foto
            </th>
          </tr>
        </thead>
        <tbody>
          {visibleOrders.length > 0 ? (
            visibleOrders.map((order) => (
              <tr key={order.id} className="hover:bg-blue-50 transition-colors">
                <td className="px-6 py-4 text-center">{order.id}</td>
                <td className="px-6 py-4 text-center">{order.status}</td>
                <td className="px-6 py-4 text-center">{order.created_at}</td>
                <td className="px-6 py-4 text-center">{order.updated_at}</td>
                <td className="px-6 py-4 text-center">
                  <img
                    src={getImageUrl(order.detail_id)}
                    alt="Producto"
                    className="h-16 w-16 object-cover rounded-md cursor-pointer"
                    onClick={() =>
                      setSelectedImage(getImageUrl(order.detail_id))
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center py-6 text-gray-500">
                No existen órdenes en tu inventario
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Controles de Paginación */}
      <div className="flex justify-between items-center mt-6 space-x-4">
        <button
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span className="text-sm font-semibold text-gray-700">
          Página {currentPage} de {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>

      {/* Modal para mostrar imagen en grande */}
      {selectedImage && (
        <ImageModal
          isOpen={!!selectedImage}
          onClose={() => setSelectedImage(null)}
          imageUrl={selectedImage}
        />
      )}
    </div>
  );
};
