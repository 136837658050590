import React from 'react';

const Coupons: React.FC = () => {
  return (
    <div>
      <h1>Coupons</h1>
      <p>Here you can find all available coupons.</p>
    </div>
  );
};

export default Coupons;
