import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { SidebarLocal } from './components/Sidebar/Sidebar';
import { Home } from './modules/Home/Home';
import { OrdersTable } from './modules/Orders/Orders';
import WorkOrderDetail from './modules/Orders/components/WorkOrderDetail';
import LoginForm from './modules/Auth/components/LoginForm';
import SignupForm from './modules/Auth/components/SignupForm';
import ChangePasswordForm from './modules/Auth/components/ChangePasswordForm';
import WorkOrders from './modules/Orders/components/workOrders';
import OrderDetail from './modules/Orders/components/orderDetail';
import Shops from './modules/Shops/Shops';
import { Declaration } from './modules/Declaration';
import { Sales } from './modules/Sales';
import { Inventory } from './modules/Orders/components/Inventory';
import { DeclarationDetail } from './modules/Declaration/components/DeclarationDetail';
import { Campaigns } from './modules/Campaigns';
import Coupons from './modules/Coupons';
import PayMethod from './modules/PayMethod';
import { CouponSales } from './modules/CouponSales';
import { ThemeProvider, defaultTheme } from '@devmyke898/fastkit';

const routes = [
  { path: '/', element: <Home />, auth: true },
  { path: '/orders', element: <OrdersTable />, auth: true },
  { path: '/order/:order_id', element: <OrderDetail />, auth: true },
  { path: '/work_orders', element: <WorkOrders />, auth: true },
  { path: '/sale_declarations', element: <Declaration />, auth: true },
  {
    path: '/declaration/:declaration_id',
    element: <DeclarationDetail />,
    auth: true,
  },
  {
    path: '/coupons',
    element: <Coupons />,
    auth: true,
  },
  {
    path: '/salesbycoupons',
    element: <CouponSales />,
    auth: true,
  },
  {
    path: '/paymethods',
    element: <PayMethod />,
    auth: true,
  },
  { path: '/sales', element: <Sales />, auth: true, role: 'seller' },
  { path: '/campaigns', element: <Campaigns />, auth: true },
  {
    path: '/work_order/:work_order_id',
    element: <WorkOrderDetail />,
    auth: true,
    role: 'admin',
  },
  { path: '/login', element: <LoginForm />, auth: false },
  { path: '/signup', element: <SignupForm />, auth: false },
  { path: '/change-password', element: <ChangePasswordForm />, auth: true },
  { path: '/inventory', element: <Inventory />, auth: true },
  { path: '/shops', element: <Shops />, auth: true, role: 'seller' },
];

const theme = {
  ...defaultTheme,
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </ThemeProvider>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [userRole, setUserRole] = React.useState<string>('guest');
  const [isOpen, setIsOpen] = React.useState(true);

  const toggleSidebar = React.useCallback(() => setIsOpen((prev) => !prev), []);

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('userRole');
    setIsAuthenticated(!!token);
    setUserRole(role ?? 'guest');
  }, [location]);

  return (
    <>
      {isAuthenticated && <Navbar />}
      <div className={`flex ${isAuthenticated ? '' : 'justify-center'}`}>
        {isAuthenticated && (
          <SidebarLocal
            isOpen={isOpen}
            onClose={toggleSidebar}
            isAuthenticated={isAuthenticated}
            userRole={userRole}
          />
        )}
        <div
          className={`flex flex-col ${
            isAuthenticated ? (isOpen ? 'ml-64' : 'ml-20') : ''
          } flex-grow`}
        >
          <div className="p-4">
            <Routes>
              {routes.map(({ path, element, auth, role }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    !auth ||
                    (isAuthenticated && (!role || userRole === role)) ? (
                      element
                    ) : (
                      <Navigate to={auth ? '/login' : '/'} />
                    )
                  }
                />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
