import React from 'react';

export const Home: React.FC = () => {
  // Función para redirigir al hacer clic en el botón
  const handleRedirect = () => {
    window.location.href =
      'https://stickerseller.cl/product-category/de-catalogo/';
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-gray-100 min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-green-500 to-blue-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-5xl font-bold tracking-wide">
            ¡Stickers únicos para darle estilo a tu día a día!
          </h1>
          <p className="mt-4 text-lg">
            Diseños exclusivos inspirados en la cultura chilena y lo que más
            amas.
          </p>
          <div className="mt-8 text-center">
            <button
              onClick={handleRedirect}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              ¡Compra ahora!
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8 fixed bottom-0 w-full ">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm">
            © 2024 Stickers Chile. Todos los derechos reservados.
          </p>
          <p className="mt-2 text-sm">Hecho con 💖 desde Santiago, Chile.</p>
        </div>
      </footer>
    </div>
  );
};
