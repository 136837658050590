import React from 'react';
import { motion } from 'framer-motion';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  children?: React.ReactNode;
  position?: 'center' | 'start' | 'end';
  disabled?: boolean;
  bgColor?: string; // Permite definir el color de fondo
  textColor?: string; // Permite definir el color del texto
  hoverColor?: string; // Permite definir el color de hover
}

export const ModernButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  className,
  text,
  disabled = false,
  bgColor = 'bg-gradient-to-r from-purple-500 to-indigo-600', // Valor por defecto
  textColor = 'text-white', // Valor por defecto
  hoverColor = 'hover:shadow-xl', // Valor por defecto
}) => {
  const disabledClass = disabled
    ? 'bg-gray-400 text-gray-300 cursor-not-allowed'
    : `${bgColor} ${textColor} cursor-pointer`;

  return (
    <motion.button
      whileHover={!disabled ? { scale: 1.1 } : {}}
      className={`px-6 py-3 flex items-center gap-2 my-6 font-semibold text-lg rounded-lg shadow-lg ${disabledClass} ${hoverColor} ${className}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {text} {children}
    </motion.button>
  );
};

export default ModernButton;
