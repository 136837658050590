import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './components/modals';
import { fetchData } from './utils';
import { api } from '../../api';
import ModernButton from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader';

interface Order {
  id: number;
  status: string;
  currency: string;
  total: number;
  billing_first_name: string;
  billing_last_name: string;
  billing_email: string;
}

export const OrdersTable: React.FC = () => {
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [selectedOrders, setSelectedOrders] = React.useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const navigate = useNavigate();
  const role = localStorage.getItem('userRole');

  React.useEffect(() => {
    const getOrders = async () => {
      setLoading(true);
      try {
        const data =
          role === 'admin'
            ? await fetchData(api.orders)
            : await fetchData(api.ordersByUser);
        setOrders(data || []);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    getOrders();
  }, [role]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const createWorkOrder = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://backend.stickerseller.cl/api/populate_extracted',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ order_ids: selectedOrders }),
        }
      );

      if (response.ok) {
        const { work_order_id } = await response.json();
        closeModal();
        navigate(`/work_order/${work_order_id}`);
      } else {
        console.error('Failed to create work order:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating work order:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (orderId: number, isChecked: boolean) => {
    setSelectedOrders((prev) =>
      isChecked ? [...prev, orderId] : prev.filter((id) => id !== orderId)
    );
  };

  // Filtrado dinámico en base a múltiples columnas
  const filteredOrders = orders.filter((order) => {
    const searchTerm = filter.toLowerCase();

    return (
      order.status.toLowerCase().includes(searchTerm) ||
      order.billing_first_name.toLowerCase().includes(searchTerm) ||
      order.billing_last_name.toLowerCase().includes(searchTerm) ||
      order.billing_email.toLowerCase().includes(searchTerm) ||
      order.id.toString().includes(searchTerm) ||
      order.total.toString().includes(searchTerm)
    );
  });

  return (
    <div className="overflow-x-auto">
      {role === 'admin' && (
        <div className="p-5">
          <label
            htmlFor="statusFilter"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Filtrar órdenes:
          </label>
          <input
            id="statusFilter"
            type="text"
            className="form-input mt-1 block w-full rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring"
            placeholder="Buscar por estado, ID, nombre, etc."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      )}

      {loading ? (
        <div className="flex justify-center py-10">
          <Loader loading={true} />
        </div>
      ) : (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="py-2 px-4">ID</th>
              <th className="py-2 px-4">Nombre</th>
              <th className="py-2 px-4">Apellido</th>
              <th className="py-2 px-4">Email</th>
              <th className="py-2 px-4">Total</th>
              <th className="py-2 px-4">Estado</th>
              <th className="py-2 px-4">Detalle</th>
              {role === 'admin' && <th className="py-2 px-4">Seleccionar</th>}
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <tr
                  key={order.id}
                  className="border-t border-gray-200 cursor-pointer"
                >
                  <td className="py-2 px-4">{order.id}</td>
                  <td className="py-2 px-4">{order.billing_first_name}</td>
                  <td className="py-2 px-4">{order.billing_last_name}</td>
                  <td className="py-2 px-4">{order.billing_email}</td>
                  <td className="py-2 px-4">{order.total}</td>
                  <td className="py-2 px-4">{order.status}</td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => navigate(`/order/${order.id}`)}
                      className="text-green-500"
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                  </td>
                  {role === 'admin' && (
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        checked={selectedOrders.includes(order.id)}
                        onChange={(e) =>
                          handleCheckboxChange(order.id, e.target.checked)
                        }
                        disabled={order.status !== 'on-hold'}
                      />
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={role === 'admin' ? 8 : 7}
                  className="py-2 px-4 text-center"
                >
                  No se encontraron órdenes.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {role === 'admin' && selectedOrders.length > 0 && (
        <div className="p-5">
          <ModernButton text="Crear OT" onClick={openModal} />
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={createWorkOrder}
        title="Crear Orden de Trabajo"
      >
        {loading ? (
          <div className="flex justify-center">
            <Loader loading={true} />
          </div>
        ) : (
          <p>
            Estás a punto de crear una orden de trabajo para las órdenes
            seleccionadas. ¿Deseas continuar?
          </p>
        )}
      </Modal>
    </div>
  );
};
