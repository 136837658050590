import React from 'react';

// - Services
import { useNavigate } from 'react-router-dom';

// - Components
import {
  ColumnDef,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import ModernButton from '../../components/Button';
import { api } from '../../api';
import { fetchData } from '../Orders/utils';
import {
  InformationCircleIcon,
  DocumentCheckIcon,
} from '@heroicons/react/20/solid';
interface Declaration {
  id: number;
  declared_at: string;
  detail: string;
}

export const Sales: React.FC = () => {
  const [declarations, setDeclarations] = React.useState<Declaration[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const navigate = useNavigate();

  const fetchDeclarations = async () => {
    try {
      const data = await fetchData(api.declarationProcess);
      if (data) {
        setDeclarations(data);
      } else {
        setError('Error al obtener las declaraciones');
      }
    } catch (err) {
      setError('Error al obtener las declaraciones');
      console.error(err);
    }
  };

  React.useEffect(() => {
    fetchDeclarations();
  }, []);

  const parseDetail = (detail: string): Record<string, number> => {
    try {
      return JSON.parse(detail);
    } catch {
      return {};
    }
  };

  const handleNavigateToDetail = (declarationId: number) => {
    navigate(`/declaration/${declarationId}`);
  };

  const columns: ColumnDef<Declaration>[] = React.useMemo(
    () => [
      {
        accessorKey: 'id',
        header: () => 'ID',
      },
      {
        accessorKey: 'detail',
        header: () => 'Status',
        cell: ({ row }) => {
          const detailData = parseDetail(row.original.detail);
          const codeCount = Object.keys(detailData).length;

          return (
            <div className="flex flex-col items-center justify-center">
              <span className="text-sm text-gray-700 font-medium">
                {codeCount ? 'Procesado' : 'Pendiente'}
              </span>
            </div>
          );
        },
      },

      {
        accessorKey: 'detail',
        header: () => 'Cantidad',
        cell: ({ row }) => {
          const detailData = parseDetail(row.original.detail);

          const codeCount = Object.keys(detailData).length;

          return (
            <div className="flex flex-col items-center justify-center">
              <span className="text-sm text-gray-700 font-medium">
                {codeCount}
              </span>
            </div>
          );
        },
      },

      {
        id: 'item',
        header: () => 'Detalle',
        cell: ({ row }) => {
          const detailData = parseDetail(row.original.detail);
          console.log(detailData, 'detailData');

          return (
            <div className="flex items-center justify-center space-x-2">
              <InformationCircleIcon
                onClick={() => handleNavigateToDetail(row.original.id)}
                className="w-5 h-5 text-blue-500 cursor-pointer hover:text-blue-700"
                title="Ver más detalles"
              />
            </div>
          );
        },
      },
      {
        accessorKey: 'declared_at',
        header: () => 'Fecha de Declaración',
        cell: ({ getValue }) => {
          const date = new Date(getValue() as string);
          return date.toLocaleDateString();
        },
      },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data: declarations,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  const handleNav = () => {
    navigate('/declaration');
  };

  return (
    <React.Fragment>
      <div className="overflow-x-auto max-w-4xl mx-auto mt-10">
        <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
          Declaraciones de Ventas
        </h5>

        <ModernButton
          className="flex  justify-self-center"
          onClick={handleNav}
          text="Declarar Ventas"
          children={<DocumentCheckIcon className="w-5 h-5" />}
        />
        <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {declarations.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-blue-50 transition-colors">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-b border-gray-200 text-center"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center py-6 text-gray-500"
                >
                  No existen declaraciones
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex justify-between items-center mt-6 space-x-2">
          <div className="flex space-x-1">
            <button
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              {'<<'}
            </button>
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              {'<'}
            </button>
          </div>

          <span className="text-sm font-semibold text-gray-700">
            Página {pagination.pageIndex + 1} de {table.getPageCount()}
          </span>

          <div className="flex space-x-1">
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              {'>'}
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              {'>>'}
            </button>
          </div>

          <select
            value={pagination.pageSize}
            onChange={(e) => table.setPageSize(Number(e.target.value))}
            className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {[10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Mostrar {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </React.Fragment>
  );
};
