import React, { useState } from 'react';
import { fetchData } from '../Orders/utils';
import {
  PlusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { api } from '../../api';
import ModernButton from '../../components/Button';

interface ICampaigns {
  user_id: number;
  declared_at: string;
  id: string;
  name: string;
  category: string;
  price: number;
  commission: number;
  start_date: string;
  end_date: string;
  status: string;
}

export const Campaigns: React.FC = () => {
  const [campaigns, setCampaigns] = useState<ICampaigns[]>([]);
  const [newCampaignName, setNewCampaignName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newPrice, setNewPrice] = useState<number>(0);
  const [newCommission, setNewCommission] = useState<number>(0);
  const [newStartDate, setNewStartDate] = useState<string>(
    new Date().toISOString().slice(0, 10)
  );
  const [newEndDate, setNewEndDate] = useState<string>(
    new Date(new Date().setDate(new Date().getDate() + 7))
      .toISOString()
      .slice(0, 10)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const userRole = localStorage.getItem('userRole');

  const fetchCampaigns = () => {
    setIsLoading(true);
    fetchData(api.campaingns, { method: 'GET' })
      .then((data) => {
        setCampaigns(data);
      })
      .catch((error) => {
        console.error('Error al obtener campañas:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addCampaign = () => {
    if (!newCampaignName.trim()) return;

    setIsCreating(true);
    const newCampaignData = {
      detail: campaigns,
      campaign_id: campaigns.length + 1,
      name: newCampaignName,
      category: newCategoryName,
      price: newPrice,
      commission: newCommission,
      start_date: newStartDate,
      end_date: newEndDate,
      declared_at: new Date().toLocaleString(),
    };

    fetchData(api.campaingns, {
      method: 'POST',
      body: JSON.stringify(newCampaignData),
    })
      .then((newCampaign) => {
        setCampaigns((prev) => [newCampaign, ...prev]);
        setNewCampaignName('');
        setNewCategoryName('');
        setNewPrice(0);
        setNewCommission(0);
        setNewStartDate(new Date().toISOString().slice(0, 10));
        setNewEndDate(
          new Date(new Date().setDate(new Date().getDate() + 7))
            .toISOString()
            .slice(0, 10)
        );
      })
      .catch((error) => {
        console.error('Error al agregar campaña:', error);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  React.useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <div className="overflow-x-auto max-w-6xl mx-auto mt-10">
      <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
        Campañas
      </h5>
      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <tr>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              ID
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Nombre
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Categoría
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Precio
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Comisión (%)
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Fecha Inicio
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Fecha Fin
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Declarado en
            </th>
            {userRole === 'admin' && (
              <th className="px-6 py-3 text-center text-xs font-medium uppercase">
                Acciones
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {/* Fila para agregar nuevas campañas */}
          {userRole === 'admin' && (
            <tr className="bg-gray-100">
              <td className="px-6 py-4 text-center text-gray-400 italic">
                Auto
              </td>
              <td className="px-6 py-4">
                <input
                  type="text"
                  placeholder="Nombre de campaña"
                  value={newCampaignName}
                  onChange={(e) => setNewCampaignName(e.target.value)}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4">
                <input
                  type="text"
                  placeholder="Categoría"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4">
                <input
                  type="number"
                  placeholder="Precio"
                  value={newPrice}
                  onChange={(e) => setNewPrice(Number(e.target.value))}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4">
                <input
                  type="number"
                  placeholder="Comisión (%)"
                  value={newCommission}
                  onChange={(e) => setNewCommission(Number(e.target.value))}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4">
                <input
                  type="date"
                  value={newStartDate}
                  onChange={(e) => setNewStartDate(e.target.value)}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4">
                <input
                  type="date"
                  value={newEndDate}
                  onChange={(e) => setNewEndDate(e.target.value)}
                  className="form-input w-full border-gray-300 rounded shadow-sm focus:ring focus:ring-blue-500"
                />
              </td>
              <td className="px-6 py-4 text-center">
                <button
                  onClick={addCampaign}
                  disabled={isCreating}
                  className="text-blue-600 hover:text-blue-800 disabled:opacity-50"
                >
                  <ModernButton text="">
                    <PlusCircleIcon className="size-6 inline" />
                  </ModernButton>
                </button>
              </td>
            </tr>
          )}
          {/* Renderizado de campañas */}
          {campaigns.length > 0 ? (
            campaigns.map((campaign) => (
              <tr
                key={campaign.id}
                className="transition-colors hover:bg-gray-100"
              >
                <td className="px-6 py-4 text-center">{campaign.id}</td>
                <td className="px-6 py-4 text-center">{campaign.name}</td>
                <td className="px-6 py-4 text-center">{campaign.category}</td>
                <td className="px-6 py-4 text-center">{campaign.price}</td>
                <td className="px-6 py-4 text-center">{campaign.commission}</td>
                <td className="px-6 py-4 text-center">{campaign.start_date}</td>
                <td className="px-6 py-4 text-center">{campaign.end_date}</td>
                <td className="px-6 py-4 text-center">
                  {campaign.declared_at}
                </td>
                {userRole === 'admin' && (
                  <td className="px-6 py-4 text-center">
                    <div className="flex justify-center space-x-2">
                      <button className="px-3 py-2 bg-green-500 hover:bg-green-600 text-white rounded-md shadow">
                        <PencilSquareIcon className="w-5 h-5 inline-block mr-1" />
                        Editar
                      </button>
                      <button className="px-3 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md shadow">
                        <TrashIcon className="w-5 h-5 inline-block mr-1" />
                        Eliminar
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9} className="py-6 text-center text-gray-500">
                No existen campañas registradas.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
