import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// - Components
import {
  DocumentCheckIcon,
  ReceiptRefundIcon,
  ShoppingCartIcon,
  GiftTopIcon,
  ArchiveBoxIcon,
  DocumentDuplicateIcon,
  ListBulletIcon,
  DocumentMagnifyingGlassIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { Sidebar } from '@devmyke898/fastkit';

import { classes } from './styles';

interface SidebarLocalProps {
  isAuthenticated: boolean;
  userRole: string;
  onClose: () => void;
  isOpen: boolean;
}

const SidebarLocalComponent: React.FC<SidebarLocalProps> = ({
  isAuthenticated,
  userRole,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const sections = useMemo(() => {
    if (!isAuthenticated) return [];

    if (userRole === 'admin') {
      return [
        {
          title: 'Admin',
          options: [
            {
              label: 'Campañas',
              icon: <ArchiveBoxIcon className={classes.iconSize} />,

              onClick: () => {
                navigate('/campaigns');
              },
            },
            {
              label: 'Órdenes',
              icon: <DocumentDuplicateIcon className={classes.iconSize} />,
              onClick: () => navigate('/orders'),
            },
            {
              label: 'Órdenes de Trabajo',
              icon: (
                <DocumentMagnifyingGlassIcon className={classes.iconSize} />
              ),
              onClick: () => navigate('/work_orders'),
            },
          ],
        },
      ];
    }

    if (userRole === 'seller') {
      return [
        {
          title: 'Mi espacio',
          options: [
            {
              label: 'Campañas',
              icon: <ArchiveBoxIcon className={classes.iconSize} />,
              onClick: () => navigate('/campaigns'),
            },
            // {
            //   label: 'Cupones',
            //   icon: <GiftTopIcon className={classes.iconSize} />,
            //   onClick: () => navigate('/coupons'),
            // },

            {
              label: 'Órdenes',
              icon: <DocumentDuplicateIcon className={classes.iconSize} />,
              onClick: () => navigate('/orders'),
            },

            {
              label: 'Ventas por Cupones',
              icon: <DocumentCheckIcon className={classes.iconSize} />,
              onClick: () => navigate('/salesbycoupons'),
            },
            {
              label: 'Metodos de Pago',
              icon: <WalletIcon className={classes.iconSize} />,
              onClick: () => navigate('/paymethods'),
            },

            // {
            //   label: 'Tiendas',
            //   icon: <ShoppingCartIcon className={classes.iconSize} />,
            //   onClick: () => navigate('/shops'),
            // },
          ],
        },
        {
          title: 'Ciclo de Venta',
          options: [
            {
              label: 'Ventas',
              icon: <DocumentDuplicateIcon className={classes.iconSize} />,
              onClick: () => navigate('/sale_declarations'),
            },
            {
              label: 'Devoluciones',
              icon: <ReceiptRefundIcon className={classes.iconSize} />,
              onClick: () => navigate('/devolutions'),
            },

            {
              label: 'Cierres',
              icon: <DocumentDuplicateIcon className={classes.iconSize} />,
              onClick: () => navigate('/orders'),
            },
          ],
        },
      ];
    }

    return [];
  }, [isAuthenticated, userRole, navigate]);

  return (
    <div className="flex">
      <Sidebar
        width="250px"
        position="left"
        type="admin"
        isOpen={isOpen}
        onClose={onClose}
        headerTitle="Sticker Seller Chile"
        sections={sections}
      />
    </div>
  );
};

export const SidebarLocal = React.memo(SidebarLocalComponent);
