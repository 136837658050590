import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';

interface ToastProps {
  isVisible: boolean; // Controla si el toast está visible
  message: string; // Mensaje que se muestra en el toast
  isLoading?: boolean; // Muestra un spinner si es true
  onClose: () => void; // Función para cerrar el toast
}

export const Toast: React.FC<ToastProps> = ({
  isVisible,
  message,
  isLoading,
  onClose,
}) => {
  return (
    <Transition
      show={isVisible}
      as={Fragment}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 transform translate-y-5"
      enterTo="opacity-100 transform translate-y-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100 transform translate-y-0"
      leaveTo="opacity-0 transform translate-y-5"
      afterLeave={onClose} // Cierra el toast al terminar la animación
    >
      <div className="fixed bottom-4 right-4 bg-gray-800 text-white p-4 rounded-lg shadow-lg">
        {isLoading ? (
          <span className="animate-spin mr-2 border-t-2 border-r-2 border-white rounded-full w-4 h-4 inline-block"></span>
        ) : null}
        {message}
      </div>
    </Transition>
  );
};
