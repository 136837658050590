import React, { useState, useEffect } from 'react';
import { fetchData } from '../Orders/utils';
import { api } from '../../api';

interface IOrder {
  id: string;
  billing_amount: number;
  discount_total: number;
  commission_amount: number;
  billing_address_1: string;
  billing_address_2: string;
  billing_city: string;
  billing_state: string;
  billing_postcode: string;
  billing_country: string;
  billing_email: string;
  billing_phone: string;
  coupon_id: string;
  created_at: string;
  updated_at: string;
}

export const CouponSales: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrders = () => {
    setIsLoading(true);
    fetchData(api.orderByCoupon) // Cambia esto por la ruta correcta de tu API
      .then((data) => {
        setOrders(data.orders || []); // Asegúrate de que `data.orders` sea el formato esperado.
      })
      .catch((error) => {
        console.error('Error al obtener órdenes:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="overflow-x-auto max-w-6xl mx-auto mt-10">
      <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
        Ventas por Cupones
      </h5>
      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <tr>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              ID Orden
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Importe Total
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Descuento
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Comisión
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Dirección 1
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Ciudad
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              País
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Teléfono
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              ID Cupón
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium uppercase">
              Creado en
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={10} className="py-6 text-center text-gray-500">
                Cargando órdenes...
              </td>
            </tr>
          ) : orders.length > 0 ? (
            orders.map((order) => (
              <tr
                key={order.id}
                className="transition-colors hover:bg-gray-100"
              >
                <td className="px-6 py-4 text-center">{order.id}</td>
                <td className="px-6 py-4 text-center">
                  ${order.billing_amount.toFixed(2)}
                </td>
                <td className="px-6 py-4 text-center">
                  ${order.discount_total.toFixed(2)}
                </td>
                <td className="px-6 py-4 text-center">
                  ${order.commission_amount.toFixed(2)}
                </td>
                <td className="px-6 py-4 text-center">
                  {order.billing_address_1}
                </td>
                <td className="px-6 py-4 text-center">{order.billing_city}</td>
                <td className="px-6 py-4 text-center">
                  {order.billing_country}
                </td>
                <td className="px-6 py-4 text-center">{order.billing_phone}</td>
                <td className="px-6 py-4 text-center">{order.coupon_id}</td>
                <td className="px-6 py-4 text-center">
                  {new Date(order.created_at).toLocaleDateString()}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className="py-6 text-center text-gray-500">
                No se encontraron órdenes.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
