import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../../api';
import { fetchData } from '../../Orders/utils';
import ImageModal from '../../Orders/components/imageModal';

interface Declaration {
  id: number;
  declared_at: string;
  detail: string; // Este campo será un JSON string
  campaign_id: number;
}

export const DeclarationDetail: React.FC = () => {
  const { declaration_id } = useParams<{ declaration_id: string }>();
  const [declaration, setDeclaration] = useState<Declaration | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [imageUrl, setCurrentImageUrl] = useState<string>('');
  const [open, setIsImageModalOpen] = useState<boolean>(false);

  const fetchDeclarations = async () => {
    try {
      const data: Declaration[] = await fetchData(api.declarationProcess); // Supongo que fetchData retorna un array
      if (data) {
        // Filtramos el dato específico por `declaration_id`
        const foundDeclaration = data.find(
          (item) => item.id === Number(declaration_id)
        );
        if (foundDeclaration) {
          setDeclaration(foundDeclaration);
        } else {
          setError('No se encontró la declaración.');
        }
      } else {
        setError('Error al obtener las declaraciones.');
      }
    } catch (err) {
      setError('Error al obtener las declaraciones.');
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDeclarations();
  }, [declaration_id]);

  const getImageUrl = (detailId: string) => {
    const [code, number] = detailId.split('-');
    return `https://stickerseller-images-2.s3.amazonaws.com/${code}/${number}.JPG`;
  };

  const handleImageClick = (imageUrl: string) => {
    setCurrentImageUrl(imageUrl);
    setIsImageModalOpen(true);
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!declaration) {
    return <div>Loading...</div>;
  }

  console.log(declaration, 'declaration');

  // Convertimos el campo `detail` (string) a un objeto JSON
  const detailItems: Record<string, number> = JSON.parse(declaration.detail);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">
        Detalle de Declaración #{declaration.id}
      </h1>
      <div className="bg-white shadow rounded-lg p-6 mb-4">
        <p>
          <strong>Fecha de Declaración:</strong>{' '}
          {new Date(declaration.declared_at).toLocaleString()}
        </p>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Productos Declarados</h2>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="py-2 px-4">ID</th>
              <th className="py-2 px-4">Cantidad</th>
              <th className="py-2 px-4">Foto</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(detailItems).map(([detailId, quantity]) => (
              <tr key={detailId} className="border-t border-gray-200">
                <td className="py-2 px-4">{detailId}</td>
                <td className="py-2 px-4">{quantity}</td>
                <td className="py-2 px-4">
                  <img
                    src={getImageUrl(detailId)}
                    alt={`Producto ${detailId}`}
                    className="h-16 w-16 object-cover rounded-md cursor-pointer"
                    onClick={() => handleImageClick(getImageUrl(detailId))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal para la imagen */}
      {open && (
        <ImageModal
          isOpen={open}
          onClose={() => setIsImageModalOpen(false)}
          imageUrl={imageUrl}
        />
      )}
    </div>
  );
};
