import React, { useState, useEffect } from 'react';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { api } from '../../api';
import { fetchData } from '../Orders/utils';
import { saveAs } from 'file-saver';
import ModernButton from '../../components/Button';

const PayMethod: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [qrData, setQrData] = useState<{
    image: string;
    template_image: string;
  } | null>(null); // Aseguramos que qrData puede ser null

  const data = [
    { label: 'Nombre', value: 'HOLISTYCS SpA' },
    { label: 'RUT', value: '77.774.682-0' },
    { label: 'Banco', value: 'Banco de Chile' },
    { label: 'Tipo de cuenta', value: 'Cuenta Vista' },
    { label: 'Número de cuenta', value: '00-015-60231-91' },
    { label: 'Correo', value: 'Ventas@stickerseller.cl' },
  ];

  const handleCopyAll = () => {
    const textToCopy = data
      .map((item) => `${item.label}: ${item.value}`)
      .join('\n');
    navigator.clipboard.writeText(textToCopy);
    setToastMessage('Todos los datos fueron copiados al portapapeles');
    setShowToast(true);

    // Ocultar el toast después de 3 segundos
    setTimeout(() => setShowToast(false), 3000);
  };

  useEffect(() => {
    // Simula la llamada al endpoint para obtener los detalles del QR
    fetchData(api.getQRdetails)
      .then((response) => response)
      .then((data) => {
        setQrData({
          image: data.qr.image,
          template_image: data.qr.template_image,
        });
      })
      .catch((error) => {
        console.error('Error al obtener el QR:', error);
      });
  }, []);

  const handleDownload = () => {
    if (!qrData?.template_image) return; // Asegurarse de que qrData no sea null y tenga la URL

    saveAs(qrData.template_image, 'asdas'); // Descarga la imagen directamente
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col lg:flex-row items-center lg:items-start justify-center p-6 space-y-6 lg:space-y-0 lg:space-x-8">
      {/* Columna izquierda: Datos */}
      <div className="relative bg-white shadow-md rounded-lg p-8 w-full max-w-lg">
        {/* Botón para copiar todos los datos */}
        <button
          onClick={handleCopyAll}
          className="absolute top-4 right-4 p-2 bg-gray-200 hover:bg-gray-300 rounded-full text-gray-600 transition duration-150"
        >
          <ClipboardIcon className="h-6 w-6" />
        </button>

        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Método de Pago
        </h1>
        <ul className="space-y-4">
          {data.map((item, index) => (
            <li
              key={index}
              className="flex justify-between items-center bg-gray-100 rounded-lg px-2 py-3"
            >
              <div>
                <p className="text-sm font-medium text-gray-600">
                  {item.label}
                </p>
                <p className="text-lg font-semibold text-gray-900">
                  {item.value}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Columna derecha: QR */}
      <div className="flex bg-white flex-col items-center bh-white shadow-md rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">
          Código QR Mercadopago
        </h2>
        {qrData ? (
          <>
            <img
              src={qrData.template_image}
              alt="Código QR"
              className="rounded-lg shadow-md"
            />

            <ModernButton
              text="Descargar Copia"
              onClick={handleDownload}
              rel="noopener noreferrer"
            />
          </>
        ) : (
          <p className="text-gray-500">Cargando QR...</p>
        )}
      </div>

      {/* Toast Notification */}
      <Transition
        show={showToast}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
      >
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
          {toastMessage}
        </div>
      </Transition>
    </div>
  );
};

export default PayMethod;
